import { Box, Container, Header, Textarea} from "@amzn/awsui-components-react";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Table from "@amzn/awsui-components-react/polaris/table";
import {InsightsRCModel, UpdateRootcauseModel} from "../../si-service-api/generated-src";
import Button from "@amzn/awsui-components-react/polaris/button";
import * as React from "react";
import {SIRootCauseApiFactory} from "../../si-service-api/SIServicesApiFactory";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Popover from "@amzn/awsui-components-react/polaris/popover";

export default function RootcausesContent({serviceDetails}) {

    const SIRootCauseApi = SIRootCauseApiFactory();

    const [commentsValue, setCommentsValue] = React.useState("");
    const [message, setMessage] = React.useState(false);

// function to populate top root cause in UI
    function populateRootcauses(fleetTopRootCauses: Array<InsightsRCModel> | undefined) {
        var data: any = [];
        serviceDetails?.fleetTopRootCauses?.map(t => {
            var ticketHref = "";
            if (t.ticketsLink != undefined && t.ticketsLink != "") {
                ticketHref = "https://t.corp.amazon.com/issues?q=" + t.ticketsLink;
            }

            data.push(
                {
                    rootcause: <b>{t.rootcause}</b>,
                    count: (t.ticketsLink != undefined) ? <a href={ticketHref} target="_blank">{t.count}</a> : t.count,
                    permFixCount: t.permFixCount,
                    tempFixCount: t.tempFixCount,
                    comments: <Textarea
                        onChange={({detail}) => {
                            setCommentsValue(detail.value)
                            t.comments = detail.value;
                        }}
                        value={t.comments ? t.comments : ""}
                        placeholder="Add Comments"
                    />
                }
            )
        });

        return data;
    }

    //function to add comments to the root causes
    function saveRootCauses(fleetTopRootCauses: Array<InsightsRCModel> | undefined) {
        var requestData: UpdateRootcauseModel;
        requestData = {
            fleetName: serviceDetails?.fleetName,
            monthYear: serviceDetails?.currMonthYr,
            rootcauses: fleetTopRootCauses
        }
        SIRootCauseApi.updateServiceRootcauses(requestData);
        setMessage(true);

        const timeId = setTimeout(() => {
            // After a few seconds, reset value
            setMessage(false);
        }, 2000)
    }

    return (
        <Container header={
            <Header variant="h2">
                Root Cause
            </Header>
        }>
            <ColumnLayout columns={1}>
                <div>
                    <Table
                        columnDefinitions={[
                            {
                                id: "rootcause",
                                header: "Root Cause",
                                cell: (item: InsightsRCModel) => item.rootcause || "-",
                                sortingField: "rootcause"
                            },
                            {
                                id: "count",
                                header: "Tickets Count",
                                cell: (item: InsightsRCModel) => item.count || "-",
                                sortingField: "count"
                            },
                            {
                                id: "tempFixCount",
                                header:
                                    <div>
                                        <Box>
                                            {"Temporary Fix Count"}
                                            &nbsp;
                                            <Popover
                                                dismissAriaLabel="Close"
                                                position="top"
                                                content="Temporary fix count is the number of tickets closed with the following closure codes: 'Successful with problems', 'No Issue', 'No plan to fix' and 'Unsuccessful'   "
                                            >
                                                <StatusIndicator type="info">

                                                </StatusIndicator>
                                            </Popover>
                                        </Box>
                                    </div>,
                                cell: (item: InsightsRCModel) => item.tempFixCount || "-",
                                sortingField: "tempFixCount"
                            },
                            {
                                id: "permFixCount",
                                header:
                                    <div>
                                        <Box>
                                            {"Permanent Fix Count"}
                                            &nbsp;
                                            <Popover
                                                dismissAriaLabel="Close"
                                                content="Permanent fix count is the number of tickets closed with the closure code 'Successful'"
                                                position="top"
                                            >
                                                <StatusIndicator type="info">
                                                </StatusIndicator>
                                            </Popover>
                                        </Box>
                                    </div>,
                                cell: (item: InsightsRCModel) => item.permFixCount || "-",
                                sortingField: "permFixCount"
                            },
                            {
                                id: "comments",
                                header: "Comments",
                                cell: (item: InsightsRCModel) => item.comments || "-",
                                sortingField: "comments"
                            }
                        ]}

                        items={populateRootcauses(serviceDetails.fleetTopRootCauses)}

                        loadingText="Loading resources"
                        sortingDisabled
                        variant="container"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No Rootcauses found</b>
                            </Box>
                        }
                    />
                    <br/>
                    <div><Button onClick={e => {
                        saveRootCauses(serviceDetails?.fleetTopRootCauses)
                    }} variant="primary">Update</Button>
                        &nbsp;&nbsp;
                        {message ?
                            <StatusIndicator>
                                Saved!
                            </StatusIndicator> : ""
                        }

                    </div>
                </div>
            </ColumnLayout>
        </Container>
    );
}