import Table from "@amzn/awsui-components-react/polaris/table";
import {Box, Header} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import * as React from "react";

/* Fetching and displaying fleet level cost information for selected month along with YTD information*/
export default function FleetMetricsTableContent({serviceDetails}) {

    return (
        <div>
            <Table
                columnDefinitions={[
                    // {
                    //     id: "service",
                    //     header: "Service Name",
                    //     cell: item => item.service || "-",
                    //     sortingField: "service"
                    // },
                    {
                        id: "actual",
                        header: serviceDetails.currMonthYr + " (Actual)",
                        cell: item => item.actual || "-",
                        sortingField: "actual"
                    },
                    {
                        id: "estimate",
                        header: serviceDetails.currMonthYr + " (Est)",
                        cell: item => item.est || "-",
                        sortingField: "est"
                    },
                    {
                        id: "estytd",
                        header: "Current Est (YTD)",
                        cell: item => item.ytd || "-",
                        sortingField: "ytd"
                    },
                    {
                        id: "goalytd",
                        header: "OP2 Goal (YTD)",
                        cell: item => item.goal || "-",
                        sortingField: "goal"
                    }
                ]}
                items={[

                    {
                        // service: serviceDetails.fleetName,
                        actual: serviceDetails.fleetCostData?.monthlyActuals <= serviceDetails.fleetCostData?.monthlyEstimate
                                ? <div style={{color:"green"}}>{serviceDetails.fleetCostData?.monthlyActuals}</div>
                                : <div style={{color:"red"}}>{serviceDetails.fleetCostData?.monthlyActuals}</div>,
                        est: serviceDetails.fleetCostData?.monthlyEstimate,
                        ytd: serviceDetails.fleetCostData?.ytdCurrentEstiamte <= serviceDetails.fleetCostData?.ytdGoal
                            ?<div style={{color:"green"}}>{serviceDetails.fleetCostData?.ytdCurrentEstiamte}</div>
                            :<div style={{color:"red"}}>{serviceDetails.fleetCostData?.ytdCurrentEstiamte}</div>,
                        goal: serviceDetails.fleetCostData?.ytdGoal,
                    }
                ]}

                loadingText="Loading resources"
                sortingDisabled
                variant="container"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No resources</b>
                        <Box
                            padding={{bottom: "s"}}
                            variant="p"
                            color="inherit"
                        >
                            No resources to display.
                        </Box>
                        <Button>Create resource</Button>
                    </Box>
                }
                header={<Header> Fleet Cost Metrics </Header>}
            />
        </div>
    );

}