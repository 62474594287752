import {
  SIServiceApi,
  SIServicesApi, UpdateInfraSettingsApi,
  UpdateOtherInfoApi,
  UpdateRootcauseApi,
  UpdateServiceActionItemsApi,
  GetServiceDetailsForReportApi, UpdateServiceConfigApi, GetMidwayUserApi
} from './generated-src';
import { getAppSetting } from '../config/AppSettings';


export default function() {
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new SIServicesApi(undefined, apiBasePath);
}

export function SIMidwayUserFactory() {
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new GetMidwayUserApi(undefined, apiBasePath);
}

//API to get all service configurations
export function SIServiceDetailsApiFactory() {
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new SIServiceApi(undefined, apiBasePath);
}

//API to read root causes
export function SIRootCauseApiFactory(){
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new UpdateRootcauseApi(undefined, apiBasePath);
}

//API to read other info which include highlights, lowlights and notes
export function SIOtherInfoApiFactory(){
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new UpdateOtherInfoApi(undefined, apiBasePath);
}

//API to update action items
export function SIActionItemsApiFactory(){
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new UpdateServiceActionItemsApi(undefined, apiBasePath);
}

//API to update infra settings such as monitor info for Max TPS
export function InfraSettingsApiFactory(){
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new UpdateInfraSettingsApi(undefined, apiBasePath);
}

//API to send report to configured emails
export function ReportApiFactory(){
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new GetServiceDetailsForReportApi(undefined, apiBasePath);
}

//API to update service configuration in configuration tab
export function UpdateServiceConfigsApiFactory(){
  const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
  return new UpdateServiceConfigApi(undefined, apiBasePath);
}