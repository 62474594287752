import {
    Box,
    Container,
    FormField,
    Header,
    Input,
    Modal,
    Toggle
} from "@amzn/awsui-components-react";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import LineChart from "@amzn/awsui-components-react/polaris/line-chart";
import Button from "@amzn/awsui-components-react/polaris/button";
// import * as React from "react";
import {populateCPUmapPlots, populateCPUXDomain, populateMapData, populateXDomain} from "../utils/CommonFns";
import {InfraSettingsApiFactory} from "../../si-service-api/SIServicesApiFactory";
import {UpdateInfraModel} from "../../si-service-api/generated-src";
import {useEffect, useState} from "react";

/* Function to fetch and display graphical representations of Max TPS,
* Memory Utilization and CPU Utilization at the fleet level. InfraMetricsContent
* provides daily aggregation as well as 3-month aggregation */
export default function InfraMetricsContent({serviceDetails, currentMonthCpu}) {

    const InfraSettingsApi = InfraSettingsApiFactory();

    const [checked, setChecked] = useState(false);
    const [infraSettingsModalVisible, setInfraSettingsModalVisible] = useState(false);
    const [marketplaceUS, setMarketplaceUS] = useState("");
    const [marketplaceEU, setMarketplaceEU] = useState("");
    const [marketplaceFE, setMarketplaceFE] = useState("");
    const [hostGroup, setHostGroup] = useState("");
    const [host, setHost] = useState("");
    const [serviceName, setServiceName] =  useState("");
    const [methodName, setMethodName] =  useState("");
    const [client, setClient] =  useState("");
    const [metricClass, setMetricClass] =  useState("");
    const [instance, setInstance] =  useState("");
    const [metric, setMetric] = useState("");

    useEffect(() => {
        if (serviceDetails.infraSettings != undefined) {
            var infraModel: UpdateInfraModel = serviceDetails.infraSettings;
            fillMonitorData(infraModel);
        }
    }, []);

    function fillMonitorData(infraModel: UpdateInfraModel) {
        setMarketplaceUS(infraModel.marketplaceUS ? infraModel.marketplaceUS : "");
        setMarketplaceEU(infraModel.marketplaceEU ? infraModel.marketplaceEU : "");
        setMarketplaceFE(infraModel.marketplaceFE ? infraModel.marketplaceFE : "");
        setHostGroup(infraModel.hostgroup ? infraModel.hostgroup : "");
        setHost(infraModel.host ? infraModel.host : "");
        setServiceName(infraModel.servicename ? infraModel.servicename : "");
        setMethodName(infraModel.methodname ? infraModel.methodname : "");
        setClient(infraModel.client ? infraModel.client : "");
        setMetricClass(infraModel.metricClass ? infraModel.metricClass : "");
        setInstance(infraModel.instance ? infraModel.instance : "");
        setMetric(infraModel.metric ? infraModel.metric : "");
    }

    function saveInfraSettings() {
        InfraSettingsApi.updateInfraSettings({
            fleetName: serviceDetails.fleetName,
            marketplaceUS: marketplaceUS,
            marketplaceEU: marketplaceEU,
            marketplaceFE: marketplaceFE,
            hostgroup: hostGroup,
            host: host,
            servicename: serviceName,
            methodname: methodName,
            client: client,
            metricClass: metricClass,
            instance: instance,
            metric: metric
        }).then(response => {
            var infraModel: UpdateInfraModel = response.data;
            fillMonitorData(infraModel);
        })
        setInfraSettingsModalVisible(false);
    }

    return (
        <Container
            header={
                <div>
                    <Header variant="h2">
                        Infrastructure Metrics &nbsp; <Button iconName="settings" onClick={e => {
                        setInfraSettingsModalVisible(true);
                    }} variant="icon"> </Button>
                        <Toggle
                            onChange={({detail}) =>
                                setChecked(detail.checked)
                            }
                            checked={checked}
                        >
                            Daily View ({serviceDetails.currMonthYr})
                        </Toggle>
                    </Header>
                </div>
            }>

            <Modal
                onDismiss={() => setInfraSettingsModalVisible(false)}
                visible={infraSettingsModalVisible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <Box float="right">
                        <Button variant="link" onClick={e => {
                            setInfraSettingsModalVisible(false);
                        }}
                        >Cancel</Button> &nbsp;
                        <Button variant="primary" onClick={e => {
                            saveInfraSettings();
                        }}
                        >Save</Button> &nbsp;
                    </Box>
                }
                header={serviceDetails.fleetName + " - PROD"}
            >
                <div>
                    <ColumnLayout columns={3}>
                        <FormField
                            label="Marketplace - US"
                        >
                            <Input
                                value={marketplaceUS}
                                onChange={event =>
                                    setMarketplaceUS(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Marketplace - EU"
                        >
                            <Input
                                value={marketplaceEU}
                                onChange={event =>
                                    setMarketplaceEU(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Marketplace - FE"
                        >
                            <Input
                                value={marketplaceFE}
                                onChange={event =>
                                    setMarketplaceFE(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Host Group"
                        >
                            <Input
                                value={hostGroup}
                                onChange={event =>
                                    setHostGroup(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Host"
                        >
                            <Input
                                value={host}
                                onChange={event =>
                                    setHost(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Service Name"
                        >
                            <Input
                                value={serviceName}
                                onChange={event =>
                                    setServiceName(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Method Name"
                        >
                            <Input
                                value={methodName}
                                onChange={event =>
                                    setMethodName(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Client"
                        >
                            <Input
                                value={client}
                                onChange={event =>
                                    setClient(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Metric Class"
                        >
                            <Input
                                value={metricClass}
                                onChange={event =>
                                    setMetricClass(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Instance"
                        >
                            <Input
                                value={instance}
                                onChange={event =>
                                    setInstance(event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Metric"
                        >
                            <Input
                                value={metric}
                                onChange={event =>
                                    setMetric(event.detail.value)
                                }
                            />
                        </FormField>
                    </ColumnLayout>
                </div>
            </Modal>
            <ColumnLayout columns={3} borders="all" variant="text-grid">
                {checked ?
                    <div>
                        <LineChart
                            series={[
                                {
                                    title: serviceDetails.fleetName,
                                    type: "line",
                                    data: populateMapData(serviceDetails.tpsDailyPlots.dailyPlots),
                                }
                            ]}
                            xDomain={populateXDomain(serviceDetails.tpsDailyPlots.dailyPlots)}
                            ariaLabel="Single data series line chart"
                            errorText="Error loading data."
                            height={250}
                            hideFilter
                            hideLegend
                            loadingText="Loading chart"
                            recoveryText="Retry"
                            xScaleType="categorical"
                            xTitle=""
                            yTitle="Daily Max TPS in K"
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No data available</b>
                                    <Box variant="p" color="inherit">
                                        There is no data available
                                    </Box>
                                </Box>
                            }
                            noMatch={
                                <Box textAlign="center" color="inherit">
                                    <b>No matching data</b>
                                    <Box variant="p" color="inherit">
                                        There is no matching data to display
                                    </Box>
                                    <Button>Clear filter</Button>
                                </Box>
                            }
                        />
                        <br/>
                        <ColumnLayout columns={2} borders="horizontal">
                            <div><b>{serviceDetails.currMonthYr}</b></div>
                            <div><b>{serviceDetails.fleetName}</b></div>
                            <div><b>Average TPS in K</b></div>
                            <div><i>{serviceDetails.tpsDailyPlots?.avg} K</i></div>
                            <div><b>Max TPS in K</b></div>
                            <div><i>{serviceDetails.tpsDailyPlots?.max} K</i></div>
                        </ColumnLayout>

                    </div>
                    :
                    <div>
                        <LineChart
                            series={[
                                {
                                    title: serviceDetails.fleetName,
                                    type: "line",
                                    data: populateMapData(serviceDetails.maxTpsPlots),
                                }
                            ]}
                            xDomain={populateXDomain(serviceDetails.maxTpsPlots)}
                            ariaLabel="Single data series line chart"
                            errorText="Error loading data."
                            height={250}
                            hideFilter
                            hideLegend
                            loadingText="Loading chart"
                            recoveryText="Retry"
                            xScaleType="categorical"
                            xTitle=""
                            yTitle="Max TPS in K"
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No data available</b>
                                    <Box variant="p" color="inherit">
                                        There is no data available
                                    </Box>
                                </Box>
                            }
                            noMatch={
                                <Box textAlign="center" color="inherit">
                                    <b>No matching data</b>
                                    <Box variant="p" color="inherit">
                                        There is no matching data to display
                                    </Box>
                                    <Button>Clear filter</Button>
                                </Box>
                            }
                        />

                    </div>
                }
                {checked ?
                    <div><LineChart
                        series={[
                            {
                                title: serviceDetails.fleetName,
                                type: "line",
                                data: populateMapData(serviceDetails.cpuUtilizationDailyPlots),
                            }
                        ]}
                        xDomain={populateXDomain(serviceDetails.cpuUtilizationDailyPlots)}
                        ariaLabel="Single data series line chart"
                        errorText="Error loading data."
                        height={250}
                        hideFilter
                        hideLegend
                        loadingText="Loading chart"
                        recoveryText="Retry"
                        xScaleType="categorical"
                        xTitle=""
                        yTitle="Daily CPU Utilization % (IMR Weighted)"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    />
                        <br/>
                        <ColumnLayout columns={2} borders="horizontal">
                            <div><b>{serviceDetails.currMonthYr}</b></div>
                            <div><b>{serviceDetails.fleetName}</b></div>
                            <div><b>Average CPU Utilization</b></div>
                            <div><i>{currentMonthCpu?.avgCpu} %</i></div>
                            <div><b>Max CPU Utilization</b></div>
                            <div><i>{currentMonthCpu?.maxCpu} %</i></div>
                        </ColumnLayout>
                    </div>
                    :
                    <div><LineChart
                        series={[
                            {
                                title: serviceDetails.fleetName,
                                type: "line",
                                data: populateCPUmapPlots(serviceDetails.cpuUtilizationPlots),
                            }
                        ]}
                        xDomain={populateCPUXDomain(serviceDetails.cpuUtilizationPlots)}
                        ariaLabel="Single data series line chart"
                        errorText="Error loading data."
                        height={250}
                        hideFilter
                        hideLegend
                        loadingText="Loading chart"
                        recoveryText="Retry"
                        xScaleType="categorical"
                        xTitle=""
                        yTitle="CPU Utilization % (IMR Weighted)"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    /></div>
                }
                {checked ?
                    <div><LineChart
                        series={[
                            {
                                title: serviceDetails.fleetName,
                                type: "line",
                                data: populateMapData(serviceDetails.memoryDailyPlots),
                            }
                        ]}
                        xDomain={populateXDomain(serviceDetails.memoryDailyPlots)}
                        ariaLabel="Single data series line chart"
                        errorText="Error loading data."
                        height={250}
                        hideFilter
                        hideLegend
                        loadingText="Loading chart"
                        recoveryText="Retry"
                        xScaleType="categorical"
                        xTitle=""
                        yTitle="Daily Memory Utilization %"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    />
                        <br/>
                        <ColumnLayout columns={2} borders="horizontal">
                            <div><b>{serviceDetails.currMonthYr}</b></div>
                            <div><b>{serviceDetails.fleetName}</b></div>
                            <div><b>Average Memory Utilization</b></div>
                            <div>
                                <i>{serviceDetails.memoryCurrentMonth?.avgMemory} %</i>
                            </div>
                            <div><b>Max Memory Utilization</b></div>
                            <div>
                                <i>{serviceDetails.memoryCurrentMonth?.maxMemory} %</i>
                            </div>
                        </ColumnLayout>
                    </div>
                    :
                    <div><LineChart
                        series={[
                            {
                                title: serviceDetails.fleetName,
                                type: "line",
                                data: populateMapData(serviceDetails.memoryUtilizationPlots),
                            }
                        ]}
                        xDomain={populateXDomain(serviceDetails.memoryUtilizationPlots)}
                        ariaLabel="Single data series line chart"
                        errorText="Error loading data."
                        height={250}
                        hideFilter
                        hideLegend
                        loadingText="Loading chart"
                        recoveryText="Retry"
                        xScaleType="categorical"
                        xTitle=""
                        yTitle="Memory Utilization %"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    /></div>
                }

            </ColumnLayout>

        </Container>
    );
}